<template>
  <div>
    <v-autocomplete :items="items" filled label="Selecione o paciente">
    </v-autocomplete>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: ["foo", "bar", "fizz", "buzz"]
    };
  }
};
</script>
